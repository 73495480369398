import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {AdministrationService} from "../../../core/services/gestion-administration/administration.service";
import {GestionAuthentificationDto} from "../../../core/dtos/gestion-authentification/gestion-authentification-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DxCheckBoxComponent} from "devextreme-angular";
import {LookUpComponent} from "../../../shared/ui/lookup/lookup.component";
import {confirm} from "devextreme/ui/dialog";
import {catchError} from "rxjs/operators";
import {TYPE_AUTHENTIFICATION} from "../../../core/constants";

@Component({
  selector: 'yo-gestion-authentification',
  templateUrl: 'gestion-authentification.html',
  styleUrls: ['gestion-authentification.scss']
})
export class GestionAuthentificationComponent implements OnInit {

  @ViewChild("delegationDroitsCheckBox") delegationDroitsCheckBox: DxCheckBoxComponent;
  @ViewChild("typeLookup") typeLookup: LookUpComponent;

  isPopupDisplayed: boolean = false;
  title: string;

  authInfos: GestionAuthentificationDto[] = [];
  gestionAuthentification: GestionAuthentificationDto = new GestionAuthentificationDto();
  typeAuthentificationExterneList: any[] = [{id: '1', libelle: TYPE_AUTHENTIFICATION.SAML2}]
  selectedType: number
  form: FormGroup;

  delegationDroits: boolean = false;



  constructor(private administrationSvc: AdministrationService,
              public utilsSvc: UtilsService,
              @Inject(FormBuilder) private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  initData = (): void => {
    this.administrationSvc.getAuthInfos().pipe(
      catchError(err => this.utilsSvc.handleError(err, true))
    ).subscribe((infos) => {
      if (infos) {
        this.authInfos = infos;
      }
    });
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      libelle: new FormControl(this.gestionAuthentification?.libelle),
      type: new FormControl(this.gestionAuthentification?.type),
      idpUrl: new FormControl(this.gestionAuthentification?.idpUrl),
    });
  }

  openFormDialog(gestionAuthentification: GestionAuthentificationDto): void {
    this.isPopupDisplayed = true;
    if (!gestionAuthentification) {
      this.gestionAuthentification = new GestionAuthentificationDto();
      this.title = 'Créer une configuration';
    } else {
      this.gestionAuthentification = gestionAuthentification
      this.selectedType = this.typeAuthentificationExterneList.find(type => type.libelle === gestionAuthentification.type).id;
      this.delegationDroits = gestionAuthentification.delegationDroits;
      this.title = 'Modification d\'une configuration';
    }
    this.initForm()
  }

   confirmDelete = async (gestionAuthentification: GestionAuthentificationDto) => {
    const result = confirm('Etes vous sûr de vouloir supprimer la configuration ' + gestionAuthentification.libelle + ' ?', 'Suppression de la configuration');
    const dialogConfirmed: boolean = await result;
    if (dialogConfirmed) {
      this.administrationSvc.deleteAuthInfo(gestionAuthentification).subscribe(() => {
        const idx = this.authInfos.findIndex(elem => elem.id === gestionAuthentification.id)
        this.authInfos.splice(idx, 1);
      })
    }
  };


  closeFormDialog(): void {
    this.isPopupDisplayed = false;
  }

  save(): void {

    this.gestionAuthentification.libelle = this.form.controls['libelle'].value;
    this.gestionAuthentification.type = this.typeLookup.getSelectedItem().libelle;
    this.gestionAuthentification.delegationDroits = this.delegationDroitsCheckBox.value
    this.gestionAuthentification.idpUrl = this.form.controls['idpUrl'].value;
    this.administrationSvc.saveAuthInfo(this.gestionAuthentification).pipe(
      catchError(err => this.utilsSvc.handleError(err, true))
    ).subscribe((saved) => {
      if (saved) {
        if (this.authInfos.findIndex(elem => elem.id === saved.id) === -1) {
          this.authInfos.push(saved);
        }
        this.closeFormDialog();
      }
    });


  }



}
