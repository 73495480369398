<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      Gestion administration
    </div>
  </dxi-item>
</dx-toolbar>

<div class="d-flex p-4 align-items-center" style="justify-content: flex-start">
  <div class="pr-4">Purge des articles inactifs</div>
  <div>
    <dx-button
      stylingMode="contained"
      text="Cliquez ici"
      type="default"
      [width]="120"
      (onClick)="showPopup()"
    ></dx-button>
  </div>
</div>

<dx-popup
  [width]="500"
  [height]="'auto'"
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="popupVisible"
  [resizeEnabled]="true"
  (onHiding)="closeDialog()"
>
  <div>
    <p>
      Êtes-vous sûr de vouloir supprimer les articles inactifs ?
    </p>
    <p>
      Cela entraînera également :
    </p>
    <ul>
      <li>l'historique des lignes de commandes dans lesquelles sont utilisées les articles</li>
      <li>les lignes des listes de besoins</li>
      <li>les articles en stock</li>
      <li>l'historique des sorties pour production</li>
      <li>l'éventuelle liaison avec les lots marchés</li>
    </ul>
    <p>
      Cette action est irréversible.
    </p>
  </div>
  <div class="p-2 d-flex justify-content-end">
    <button
      class="btn btn-primary"
      type="button"
      (click)="purgeInactiveArticles()">
      Confirmer
    </button>
  </div>
</dx-popup>

<yo-gestion-authentification></yo-gestion-authentification>
