<dx-toolbar class="yo-toolbar">
  <dxi-item location="before" class="d-flex">
    <div class="d-flex flex-row align-items-center">
      Gestion de l'authentification externalisée
    </div>
    <yo-button
      iconClass="fas fa-plus"
      label="CRÉER"
      tooltip="Créer une nouvelle configuration"
      (onClick)="openFormDialog(null)"
      class="ml-3">
    </yo-button>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="authInfos"
  keyExpr="id"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>

  <dxi-column dataField="id" caption="Actions" cellTemplate="templateActions" [width]="100"
              [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Libelle"></dxi-column>
  <dxi-column dataField="type" caption="Type"></dxi-column>
  <dxi-column dataField="delegationDroits" caption="Gestion des droits déléguée"></dxi-column>
  <dxi-column dataField="idpUrl" caption="Url de l'IDP"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-edit"
                    [yoTooltip]="'Modifier la configuration '"
                    (yoNavigation)="openFormDialog(cell.row.data)"
    >
    </yo-cell-button>
    <yo-cell-button
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-trash"
                    [yoTooltip]="'Supprimer la configuration '"
                    (yoNavigation)="confirmDelete(cell.row.data)"
    >
    </yo-cell-button>
  </div>

</dx-data-grid>


<dx-popup
  [showTitle]="true"
  [title]="title"
  [visible]="isPopupDisplayed"
  [width]="800"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeFormDialog()"
>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Libelle">Libelle <span class="danger-color">*</span></div>
          <div class="value">
            <dx-text-box class='inputfield' placeholder="Libelle" formControlName="libelle"
            ></dx-text-box>
          </div>
        </div>
    </div>
    <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="TypeAuthentification">Authentification via <span class="danger-color">*</span></div>
          <div class="value">
            <yo-lookup
              #typeLookup
              [data]="typeAuthentificationExterneList"
              [selectedItem]="selectedType"
            >
            </yo-lookup>
          </div>
        </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="DelegationDroits">Délégation des droits <span class="danger-color">*</span></div>
        <div class="value">
          <dx-check-box
            #delegationDroitsCheckBox
            [value]="delegationDroits"
          ></dx-check-box>
        </div>
      </div>
    </div>
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="IdpUrl">Url de l'identity provider <span class="danger-color">*</span></div>
        <div class="value">
          <dx-text-box class='inputfield' placeholder="Url" formControlName="idpUrl"
          ></dx-text-box>
        </div>
      </div>
    </div>

  </form>

  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button
        [tooltip]="gestionAuthentification?.id ?'Enregistrer la nouvelle configuration':'Sauvegarde des modifications'"
        type="submit"
        iconClass="fas fa-save"
        buttonClass="cta-success"
        (onClick)="save()">
      </yo-button>
      <yo-button tooltip="Fermer"
                 buttonClass="cta-delete"
                 (onClick)="closeFormDialog()"
                 iconClass="fas fa-times"
      ></yo-button>
    </div>
  </div>
</dx-popup>
