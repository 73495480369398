<div [ngStyle]="{
  width:'100vw',
  height:'100vh',
  backgroundImage: 'url(dolrest/utils/parametre-image?imageName=background&date=1)',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover' }">

  <div class="center">
    <p-card>

      <p-header>
        <div class="pg-14">
          <i class="icon-logo-yoni-y yoni-color mg-r-10"></i><strong class="genlabel-color font-20">DATAMEAL
          ONLINE</strong>
        </div>

      </p-header>

      <div class="width-500">
        <form #formLogin="ngForm" (ngSubmit)="login()">

          <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon pg-12"><i class="fas fa-user"></i></span>
                <input pInputText name="login" placeholder="login..." [(ngModel)]="userLogin">
              </div>
            </div>
          </div>

          <div class="p-grid p-fluid mg-t-10">
            <div class="p-col-12 p-md-12 ">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon pg-12"><i class="fas fa-lock"></i></span>
                <input name="password" pInputText [type]="hide ? 'password' : 'text'" placeholder="mot de passe..."
                       [(ngModel)]="userPassword">
                <span class="p-inputgroup-addon pg-12">
              <i *ngIf="hide" class="far fa-eye cursor" (click)="hide = false"></i>
              <i *ngIf="!hide" class="far fa-eye-slash cursor" (click)="hide = true"></i>
            </span>
              </div>
            </div>
          </div>

          <div class="d-flex mg-t-14">
            <div class="mr-auto  genlabel">
              <yo-infos-version [displayLabel]="true"></yo-infos-version>
            </div>
            <div class="d-flex flex-column">

              <table>
                <tr>
                  <td>
                    <yo-button label="Se connecter" type="submit"
                               iconClass="fas fa-sign-in-alt"
                               [disabled]="loading">
                    </yo-button>
                  </td>
                  <td width="34">
                    <i *ngIf="loading" class="fas fa-sync-alt speed-spin mg-l-5 line-height-30 "></i>
                  </td>
                </tr>
              </table>


              <a title="Mot de passe oublié" class="cursor genlabel a-underline mg-t-14 font-14"
                 (click)="openDialogMotDePasseOublie()">
                Mot de passe oublié
              </a>
            </div>
          </div>
        </form>
      </div>

      <ng-template  pTemplate="footer">
        <p-panel *ngIf="authInfos.length > 0" header="Autres connexions" [toggleable]="true" [collapsed]="true">
          <div class="flexibleCol">
            <ng-container *ngFor="let connexion of authInfos">
              <yo-button  [label]="connexion.libelle"
                          iconClass="fas fa-id-badge"
                          class="mt-1 mb-1"
                          [disabled]="loading"
                          (onClick)="performSsoLogin(connexion)">
              </yo-button>
            </ng-container>

          </div>
        </p-panel>
      </ng-template>

    </p-card>
  </div>
</div>


<!--DIALOG MOT DE PASSE OUBLIE-->
<yo-mot-de-passe-oublie></yo-mot-de-passe-oublie>
