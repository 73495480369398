// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[272b49b40cea0d4f9be905ca58a6fbaab007be9e]6.1-10537(272b49b40c)-C29/08/2024-09:26:09-B29/08/2024-09:32:51' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[272b49b40cea0d4f9be905ca58a6fbaab007be9e]6.1-10537(272b49b40c)-C29/08/2024-09:26:09-B29/08/2024-09:32:51",
  branch: "272b49b40cea0d4f9be905ca58a6fbaab007be9e",
  latestTag: "6.1",
  revCount: "10537",
  shortHash: "272b49b40c",
  longHash: "272b49b40cea0d4f9be905ca58a6fbaab007be9e",
  dateCommit: "29/08/2024-09:26:09",
  dateBuild: "29/08/2024-09:32:51",
  buildType: "Ansible",
  } ;
