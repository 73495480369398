import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AUTH} from '../../constants';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  /**
   * Ajout du token oauth sur chaque requete, s'il existe
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Get the auth header from the localstorage.
    const authHeader = localStorage.getItem(AUTH.token);

    //Si le token existe
    if (authHeader) {

      // Clone the request to add the new header.
      //Prefix it with 'Bearer' for oauth implementations
      const authReq = req.clone({setHeaders: {Authorization: 'Bearer ' + authHeader}});
      // Pass on the cloned request instead of the original request.
      return next.handle(authReq);
    }

    return next.handle(req);
  }

}
