import {EnvironnementUtilisateurDTO} from './environnement-utilisateur-dto';
import {ProfilDTO} from './profil-dto';
import {ObjectDTO} from './object-dto';
import {Environnement_PlcDTO} from "./administration/environnement-plc-dto";


export class UtilisateurDTO extends ObjectDTO {

  nom: string;
  prenom: string;
  login: string;
  password: string;
  confirmationPassword: string;
  applyPassword: boolean;
  email: string;
  profil: ProfilDTO;
  typeAuthentification: string;

  environnementUtilisateurDTOS: EnvironnementUtilisateurDTO[] = [];

  environnementPlc: Environnement_PlcDTO;

  constructor() {
    super();
    this.id = 0;
    this.password = null;
    this.actif = true;
    this.applyPassword = false;
    this.environnementUtilisateurDTOS = [];
  }

}
