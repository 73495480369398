
export class GestionAuthentificationDto {

  id: number

  libelle: string

  type: string

  delegationDroits: boolean

  idpUrl: string

}
