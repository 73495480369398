import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UtilisateurDTO} from '../../../core/dtos/utilisateur-dto';
import {UtilsService} from '../../../core/utils/utils.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilisateurResolverService} from '../../utilisateur-resolver.service';
import {UtilisateurService} from '../../../core/utilisateur.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {AUTH, MSG_KEY, MSG_SEVERITY, TYPE_AUTHENTIFICATION, TYPE_AUTHENTIFICATION_LIST} from '../../../core/constants';
import {EnvironnementDTO} from '../../../core/dtos/environnement-dto';
import {BaseComponent} from '../../../base-component';
import {ProfilDTO} from '../../../core/dtos/profil-dto';
import {EnvironnementUtilisateurDTO} from '../../../core/dtos/environnement-utilisateur-dto';
import {RoleDTO} from "../../../core/dtos/role-dto";
import {Environnement_PlcDTO} from "../../../core/dtos/administration/environnement-plc-dto";
import {ToastService} from "../../../core/services/technique/toast.service";
import {LookUpComponent} from "../../../shared/ui/lookup/lookup.component";
import {TagBoxComponent} from "../../../shared/ui/tag-box/tag-box.component";
import {ProfilsService} from "../../../core/services/entities/profils.service";
import {EnvironnementService} from "../../../core/services/entities/gestionenvironnements/environnement.service";
import {EnvironnementPlcService} from "../../../core/services/portail/environnement-plc.service";
import {DxCheckBoxComponent} from "devextreme-angular";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {GestionAuthentificationDto} from "../../../core/dtos/gestion-authentification/gestion-authentification-dto";

@Component({
  selector: 'yo-ficheidentite-utilisateur',
  templateUrl: './ficheidentite-utilisateur.component.html',
  styleUrls: ['./ficheidentite-utilisateur.component.scss']
})
export class FicheidentiteUtilisateurComponent extends BaseComponent implements OnInit {

  @ViewChild("profilLookup") profilLookup: LookUpComponent;
  @ViewChild("typeAuthLookup") typeAuthLookup: LookUpComponent;
  @ViewChild("environnementsTagBox") environnementTagBox: TagBoxComponent;
  @ViewChild("environnementsPlcLookUp") environnementsPlcLookUp: LookUpComponent;
  @ViewChild("actifCheckBox") actifCheckBox: DxCheckBoxComponent;

  isProfilPopupDisplayed: boolean = false;
  showProfilDetails: boolean = false;
  showPortail: boolean = false;
  isPopupDisplayed: boolean = false;
  title = "Créer un utilisateur";
  envPointDeLivraisonSelected: Environnement_PlcDTO;
  actif: boolean = true;

  // Subscriptions
  private subSave: Subscription;
  subEnvPointDeLivraison: Subscription;
  subEnvironnements: Subscription;
  subAllProfiles: Subscription;
  subOpenDialog: Subscription;
  subDeleteUtilisateur: Subscription;

  utilisateur: UtilisateurDTO;
  envPointDeLivraisonList: Environnement_PlcDTO[] = [];
  environnementList: EnvironnementDTO[] = [];
  environmentsSelected: number[];
  allProfils: ProfilDTO[] = [];
  selectedProfil: number;
  typeAuthentificationSelected: number;

  form: FormGroup;

  motDePasse: string = '';
  motDePasseConfirmation: string = '';

  isAuthExterne: boolean;
  typeAuthentificationList: any[];


  constructor(public utils: UtilsService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private profilSvc: ProfilsService,
              private utilisateurSvc: UtilisateurService,
              private utilisateurResolverService: UtilisateurResolverService,
              private cdr: ChangeDetectorRef,
              private environnementSvc: EnvironnementService,
              private envPlcSvc: EnvironnementPlcService,
              private toastSvc: ToastService,
              private auth2Svc: Auth2Service) {
    super();
  }

  ngOnInit() {
    this.initProfils();
    this.initEnvironnements();
    this.initPointsDeLivraison();
    this.initForm();
    this.initOpenDialogSubscription();
    this.initAuthConfig();
  };

  initAuthConfig = (): void => {
    this.isAuthExterne = this.auth2Svc.getIsAuthentificationExterne() === 'true';
  }

  initForm = (): void => {
    this.form = new FormGroup({
      nom: new FormControl(this.utilisateur?.nom),
      prenom: new FormControl(this.utilisateur?.prenom),
      email: new FormControl(this.utilisateur?.email),
      code: new FormControl(this.utilisateur?.code),
      login: new FormControl(this.utilisateur?.login),
      motDePasse: new FormControl(""),
      motDePasseConfirmation: new FormControl(""),
    });
  }

  private initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.utilisateurSvc.openDialog$.subscribe(utilisateur => {
      this.isPopupDisplayed = true;
      if (!utilisateur) {
        this.utilisateur = new UtilisateurDTO();
        this.title = 'Créer un utilisateur';
        if (this.isAuthExterne) {
          this.typeAuthentificationList = TYPE_AUTHENTIFICATION_LIST.filter(elem => elem.isExterne);
        } else {
          this.typeAuthentificationList = TYPE_AUTHENTIFICATION_LIST;
        }
      } else {
        this.utilisateur = utilisateur;
        this.selectedProfil = utilisateur.profil.id;
        this.envPointDeLivraisonSelected = utilisateur.environnementPlc;
        this.environmentsSelected = this.getSelectedEnvironnement();
        const userTypeAuthentification = TYPE_AUTHENTIFICATION_LIST.find(type => type.libelle === utilisateur.typeAuthentification);
        this.typeAuthentificationSelected = userTypeAuthentification.id;
        this.typeAuthentificationList = TYPE_AUTHENTIFICATION_LIST.filter(elem => elem.isExterne === userTypeAuthentification.isExterne);
        this.title = 'Modification d\'un utilisateur';
      }
      this.initForm()
    })
  }

  private getSelectedEnvironnement = (): number[] => {
    return this.environnementList
      .filter(env => {
        let environnementIds = env.environnementUtilisateurDTOList.map(e => e.id);
        let userEnvIds = this.utilisateur.environnementUtilisateurDTOS.map(e => e.id);
        return environnementIds.find(e => userEnvIds.includes(e));
      }).map(env => env.id);
  }

  save = () => {
    this.initUtilisateur();
    if (this.showPortail)
      this.utilisateur.environnementPlc = this.environnementsPlcLookUp.getSelectedItem();
    // Le changement de mot de passe ne doit pas s'opérer pour une mise à jour dans laquelle le champ mot de passe n'a pas
    // été touché :
    if (this.utilisateur.id && !this.utilisateur.password && !this.utilisateur.confirmationPassword) {
      this.utilisateur.applyPassword = false;
    }

    this.subSave = this.utilisateurSvc.save(this.utilisateur).subscribe(data => {
        let result: ResponseWrapper<UtilisateurDTO> = data;

        if (result.inError) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${result.resultMessage}`);
        } else {
          this.utilisateur = result.one;
          this.initEnvironnements();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde a été réalisée avec succès`);
          this.utilisateurResolverService.utilisateurAnnounceSource.next(this.utilisateur);
          this.closeDialog();
        }
      },
      err => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible d'enregistrer l utilisateur '${this.utilisateur.login}'`));
  };


  /**
   * Suppression de l'UtilisateurDTO passé en paramètre.
   * @param {UtilisateurDTO} utilisateurDTO
   */
  delete = (utilisateurDTO: UtilisateurDTO) => {
    this.subDeleteUtilisateur = this.utilisateurSvc.deleteUtilisateur(utilisateurDTO);
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDeleteUtilisateur);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subAllProfiles);
    this.utils.unsubscribe(this.subEnvironnements);
    this.utils.unsubscribe(this.subEnvPointDeLivraison);
    this.utils.unsubscribe(this.subSave);
  }

  closeDialog = (): void => {
    this.isPopupDisplayed = false;
  }


  private initProfils = (): void => {
    this.subAllProfiles = this.profilSvc.getAllFromEnvironnement().subscribe(response => this.allProfils = response.resultList);
  }

  private initEnvironnements() {
    this.subEnvironnements = this.environnementSvc.findAll().subscribe(response => this.environnementList = response);
  }

  private initPointsDeLivraison() {
    this.subEnvPointDeLivraison = this.envPlcSvc.getAll().subscribe(response => this.envPointDeLivraisonList = response.resultList);
  }

  onProfilChange = (data: number): void => {
    this.selectedProfil = data;
    this.utilisateur.profil = this.allProfils.find(profil => profil.id === data)
    this.checkHasUserPortailRole();
    this.showProfilDetails = true;
  }

  onTypeAuthChange = (data: number): void => {
    this.utilisateur.typeAuthentification = TYPE_AUTHENTIFICATION_LIST.find(type => type.id === data).libelle;
  }

  private checkHasUserPortailRole = (): void => {
    this.showPortail = false;
    this.utilisateur?.profil?.roleList.forEach((role: RoleDTO) => {
      if (role.code === "ROLE_PORTAIL_USER")
        this.showPortail = true;
    })
  }

  displayPopUp = (): void => {
    this.isProfilPopupDisplayed = true;
  }

  closeProfilPopUp() {
    this.isProfilPopupDisplayed = false;
  }

  getEnvironnementUtilisateurDTOS = () => {
    return this.environnementTagBox.getSelectedItems().map((env: EnvironnementDTO) => {
      const lightEnv: EnvironnementDTO = new EnvironnementDTO();
      lightEnv.id = env.id; // On n'a pas besoin des autres infos du DTO complètement toxiques, lourds pour le réseau et inutile pour la sauvegarde
      return ({
        environnementDTO: lightEnv,
        idUtilisateur: this.utilisateur.id,
        id: env.id
      }) as EnvironnementUtilisateurDTO;
    });
  }

  private initUtilisateur = (): void => {
    this.utilisateur.environnementUtilisateurDTOS = this.getEnvironnementUtilisateurDTOS();
    this.utilisateur.profil = this.profilLookup.getSelectedItem();
    this.utilisateur.password = this.motDePasse;
    this.utilisateur.confirmationPassword = this.motDePasseConfirmation;
    this.utilisateur.applyPassword = true;
    this.utilisateur.code = this.form.controls['code'].value;
    this.utilisateur.login = this.form.controls['login'].value;
    this.utilisateur.email = this.form.controls['email'].value;
    this.utilisateur.password = this.form.controls['motDePasse'].value;
    this.utilisateur.confirmationPassword = this.form.controls['motDePasseConfirmation'].value;
    this.utilisateur.nom = this.form.controls['nom'].value;
    this.utilisateur.prenom = this.form.controls['prenom'].value;
    this.utilisateur.actif = this.actifCheckBox.value;
    this.utilisateur.typeAuthentification = this.typeAuthLookup.getSelectedItem().libelle;
  }

  /**
   * Le champs motDePasse et motDePasseConfirmation du formulaire ne sont visiblement uniquement si :
   *  - L'utilisateur est connecté via login/password
   *  - Pour les utilisateurs qui utilisent la connexion interne login/password
   *  - Pour les utilisateurs connecté via SSO, pas de champs motDePasse,
   *    il ne peuvent pas non plus accèder aux champs motDePasse des autres utilisateurs qui serait en connexion interne
   */
  canAccessFieldPassword(): boolean {
    if (this.utilisateur === undefined || this.utilisateur.typeAuthentification === undefined) {
      return !(this.isAuthExterne);
    } else {
      return !(this.isAuthExterne || this.utilisateur.typeAuthentification !== TYPE_AUTHENTIFICATION.PASSWORD)
    }
  }

}

