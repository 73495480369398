import {Injectable} from "@angular/core";
import {HttpService} from "../technique/http.service";
import {UtilsService} from "../../utils/utils.service";
import {Observable, of} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {catchError} from "rxjs/operators";
import {GestionAuthentificationDto} from "../../dtos/gestion-authentification/gestion-authentification-dto";

export const URL_PURGE_INACTIVE_ARTICLE = 'dolrest/administration/purgeInactiveArticlesUE';
export const URL_GESTION_AUTHENTIFICATION = 'dolrest/administration/authentification';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  constructor(private httpSvc: HttpService,
              public utils: UtilsService) { }

  /**
   * Suppression des articles inactifs
   * @returns {Observable<void>}
   */
  purgeInactiveArticles = (): Observable<ResponseWrapper<string>> => {
    return this.httpSvc.get(URL_PURGE_INACTIVE_ARTICLE, null);
  }

  getAuthInfos(): Observable<GestionAuthentificationDto[]> {
    return this.httpSvc.get(URL_GESTION_AUTHENTIFICATION);
  }

  saveAuthInfo(body: GestionAuthentificationDto): Observable<GestionAuthentificationDto> {
    return this.httpSvc.post(URL_GESTION_AUTHENTIFICATION, body)
  }

  deleteAuthInfo(gestionAuthentification: GestionAuthentificationDto): Observable<ResponseWrapper<boolean>> {
    return this.httpSvc.delete(URL_GESTION_AUTHENTIFICATION + '/' + gestionAuthentification.id).pipe(
      catchError(error => this.utils.handleError(error, true)));
  }



}
